// FiraSansExtraCondensed
// FiraSansCondensed

$color-jacksons-purple: #302884;
$colorjacksons-purple-09: rgba(48,40,132,.9);
$color-zambezi: #5F5E5E;
$color-tangerine-yellow: #FFCD00; 
$color-star-dust: #9D9D9C;
// links-backs
$color-china-ivory: #FFF5CC;
$color-sunglow: #FFD733;
// button
$color-drover: #FFEB99;

$color-black-magic: #302A24;
$color-grape: #444345;
$color-black: #000000;
$color-white: #ffffff;
$color-shuttle-grey: #51626F;
$color-ship-grey: #575756;
$color-dim-gray: #626262;
$color-whisper: #E4E4E4;
$color-white-smoke: #F2F2F2;



// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;
$color-wan-white: #E2E1DE;
$color-silver: #C1C0C0;
$color-black-russian: #101828;
$color-golden-ray: #ffcc00;


.color-navy-blue {
	color: $color-navy-blue;
}
